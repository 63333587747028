import { takeLatest, call, put, all } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import api from '../../../services/api'

export function* signIn(action: ActionType<typeof actions.signIn>) {
  try {
    const { payload } = action
    const { data } = yield call(api.post, `usuarios/autenticacao/`, payload)
    yield put(actions.signInSuccess(data))
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || 'Falha inesperada ao Realizar Login'
    yield put(actions.signInError(errorMessage))
  }
}

export function* signUp(action: any) {
  try {
    const { payload } = action
    yield console.log('saga', action)
    const { data } = yield call(
      api.post,
      `consumidores/cadastrar_cliente/`,
      payload,
    )
    yield put(actions.signUpSuccess(data))
  } catch (error) {
    yield console.log(error)
    const errorMessage = `${error?.response?.data?.message || 'Falha ao Cadastrar'} ${error?.response?.data?.exceptions[0] || ''}`
    yield put(actions.signInError(errorMessage))
  }
}

export default all([
  takeLatest('@user/SIGN_IN', signIn),
  takeLatest('@user/SIGNUP', signUp),
])
