import styled from 'styled-components'
import BannerDefault from '../../../assets/img/bannerDefault.png'

interface imgProps {
  imgUrl: string | null
}

const Container = styled.div`
  margin-top: 0.5rem;
  @media (min-width: 600px) {
    margin-top: 0.35rem;
  }
`

const Banner = styled.div<imgProps>`
  background-image: url(${({ imgUrl }) => imgUrl || BannerDefault});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 56.25vw;
  @media (min-width: 1024px) {
    width: 100%;
    height: 65vh;
  }
`

const Styled = { Container, Banner }

export default Styled
