import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  :root {
    font-size: 60%;

    --color-background: #F9FAFA;
    --color-background-darker: #E1E1E1;
    --color-primary: #E14B5A;
    --color-primary-lighter: #FD7E77;
    --color-primary-highlight: #FF3800;
    --color-button-lighter: #FF5C53;
    --color-secondary-button: #FEF3DD;
    --color-text-in-white: #373737;
    --color-text-in-background: #666666;
    --color-text-disabled: #909090;
    --color-text-primary: #C70039;
    --color-text-complement: #3A6A75;
    --color-text-secondary: #668B94;
    --color-text-in-primary-background: #FFFFFF;
    --color-border: #8890A6;
    --color-border-lighter: #B4B4B4;
    --color-border-error: #B91C1C;
    --color-icons: #F9AC19;
    --color-warning: #FFEB3B;
    --color-icons-secondary: #79828B;
    --color-success: #19BC75;
    --color-success-darker: #009421;
    --color-default: #000;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    //font: 400 1.6rem Roboto;
    font-family: var(--font-family-base); // Roboto /* or var(--font-family-highlight) token. */
    font-weight: var(--font-weight-medium);
    h1, h2, h3, h4, h5, h6 {
      font-family: var(--font-family-highlight)!important; // AMX
      font-weight: var(--font-weight-bold);
    }
  }

  html, body, #root {
    background: var(--color-background);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  body {
    background: transparent;
  }

  #root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    width: 90vw;
    max-width: 700px;
  }

  .position-absolute {
    position: absolute;
  }

  @media (min-width: 700px) {
    :root {
      font-size: 62.5%;
    }
  }
`
