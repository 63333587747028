import React, { useState } from "react";
import { RequestButtom } from "../App/RequestNewPassword/styles";
import {ChangeContainer, 
        BoxContent,
        TittleBox,
        InputPassword,
        MessageError,
        MessageSucess,
        Envolve,
        ReturnButtom
        } from './styles';
import api from "../../services/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";


const ChangePassword = () => {

    const [password, setPassword] = useState('');
    const [newPassword, setNewpassword] = useState('');
    const [repeatNew, setRepeatNew] = useState('');
    const [erroPass, setErroPass] = useState(false)
    const [erro400, setErro400] = useState(false)
    const [sucess, setSucess] = useState(false)
    const [show, setShow] = useState(false)

    const mostrar = <FontAwesomeIcon icon={faEye} style={{ position:'absolute', top:'20px', right:'20px', color:'#c4c4c4' }} />
    const esconder = <FontAwesomeIcon icon={faEyeSlash} style={{ position:'absolute', top:'20px', right:'20px', color:'#c4c4c4' }} />

    const showPassword = () => {
        setShow(!show)
    }

const changePassword = () => {
        if(newPassword === repeatNew){
            api.post('/usuarios/alterar_senha/',
             {
                 'senha_atual':password,
                 'nova_senha':newPassword
             }).then(resp => {
                 console.log(resp.data)
                 setSucess(true)
                 setPassword('')
                 setNewpassword('')
                 setRepeatNew('')
                 setTimeout(()=> setSucess(false), 8000)
             }).catch(err => {
                 console.log(err)
                 setErro400(true)
                 setTimeout(()=> setErro400(false), 5000)
             })}else{
                        setErroPass(true)
                        setTimeout(()=> setErroPass(false), 5000)
                    }
                }

    return(
        <ChangeContainer>
            <BoxContent>
                <ReturnButtom><Link to='/perfil'><FontAwesomeIcon icon={faArrowLeft} style={{ color:'#d00000' }}/></Link></ReturnButtom>
                <TittleBox>Alterar senha de usuário</TittleBox>
                <Envolve>
                    <InputPassword
                    type={show ? "text" : "password"}
                    placeholder="Senha atual"
                    value={password}
                    onChange={(e)=> setPassword(e.target.value)} />
                    <button onClick={showPassword}>{show ? mostrar : esconder}</button>
                </Envolve>
                <InputPassword
                 type="text" 
                 placeholder="Nova senha"
                 value={newPassword}
                 onChange={(e)=> setNewpassword(e.target.value)} />
                <InputPassword
                 type="text" 
                 placeholder="Confirme nova senha"
                 value={repeatNew}
                 onChange={(e) => setRepeatNew(e.target.value)} />

                 {erroPass &&<MessageError>Repita a nova senha corretamente.</MessageError>}
                 {erro400 &&<MessageError>A senha atual informada está incorreta.</MessageError>}
                 {sucess &&<MessageSucess>Senha atualizada com sucesso.</MessageSucess>}
                <RequestButtom
                 onClick={changePassword}
                 disabled={password === '' || newPassword === '' || repeatNew === '' }>
                     Alterar
                </RequestButtom>
            </BoxContent>
        </ChangeContainer>       
    )
}

export default ChangePassword;

