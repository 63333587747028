import React from 'react'
import * as S from './styles'

interface ValidationMessageProps {
  secondary?: boolean
}

const ValidationMessage: React.FC<ValidationMessageProps> = ({
  children,
  secondary,
}) => {
  return <S.Message secondary={secondary}>{children}</S.Message>
}

export default ValidationMessage
