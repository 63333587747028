import styled from 'styled-components'
import { FaMapMarkerAlt, FaArrowLeft } from 'react-icons/fa'
import avatarPlaceholder from '../../../../assets/img/avatar.png'

interface ImgProps {
  imgUrl: string | null
}

interface ContactProps {
  outline?: boolean
}

const Picture = styled.div<ImgProps>`
  background-image: url(${({ imgUrl }) => imgUrl || avatarPlaceholder});
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 45vh;
`

const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background: var(--color-primary);
`

const Name = styled.h2`
  color: var(--color-text-in-primary-background);
  font-family: Poppins;
  font-size: 18pt;
  text-align: center;
`

const Info = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`

const MapIcon = styled(FaMapMarkerAlt)`
  color: var(--color-success);
  font-size: 15pt;
  margin-right: 1rem;
`

const BackIcon = styled(FaArrowLeft)`
  font-size: 15pt;
  margin-right: 1rem;
`

const Address = styled.p`
  font-family: Roboto;
  font-weight: 300;
  text-align: center;
`

const Contact = styled.div<ContactProps>`
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 6rem;
  width: 26rem;
  margin: 6rem auto;
  border-radius: 0.75rem;
  color: ${({ outline }) => (outline ? 'var(--color-primary)' : 'white')};
  border: ${({ outline }) =>
    outline ? '2px solid var(--color-primary)' : 'none'};
  background: ${({ outline }) =>
    outline ? 'transparent' : 'var(--color-primary)'};
`

const Phone = styled.h2`
  font-size: 15pt;
  font-weight: 500;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Details = {
  Picture,
  Profile,
  Name,
  Info,
  MapIcon,
  BackIcon,
  Address,
  Contact,
  Phone,
}

export default Details
