import { TecnicosAction, TecnicosState } from './types.d'

const initialState: TecnicosState = {
  data: {
    total_paginas: 0,
    pagina_atual: 0,
    tecnicos: [
      {
        nome: '',
        sobrenome: '',
        cpf_cnpj: '',
      },
    ],
  },
  tecnicoSelecionado: {
    id: 0,
    nome: '',
    sobrenome: '',
    cpf_cnpj: '',
    telefone: '',
    foto_tecnico: null,
    endereco: null,
    cep: '',
    numero: 0,
    sem_numero: true,
    pais: null,
    uf: null,
    cidade: null,
    complemento: null,
    ponto_referencia: null,
    horario_entrada: '',
    horario_saida: '',
    data_admissao: null,
    dias_disponiveis: ['0'],
    valor_medio_servico: 0.0,
    habilidades: [
      {
        id: 0,
        nome: '',
        descricao: '',
      },
    ],
  },
  filtroCep: '',
  status: null,
  loading: false,
  message: null,
}

const tecnicos = (
  state = initialState,
  action: TecnicosAction,
): TecnicosState => {
  switch (action.type) {
    case '@tecnicos/CHANGE_CEP':
      return {
        ...state,
        filtroCep: action.payload.cep,
      }
    case '@tecnicos/GET':
      return {
        ...state,
        loading: true,
      }
    case '@tecnicos/GET_SUCCESS':
      return {
        ...state,
        data: action.payload,
        status: 'success',
        loading: false,
      }
    case '@tecnicos/GET_ERROR':
      return {
        ...state,
        data: {
          total_paginas: 0,
          pagina_atual: 0,
          tecnicos: [],
        },
        message: action.message,
        status: 'error',
        loading: false,
      }
    case '@tecnicos/detalhes/GET':
      return {
        ...state,
        status: null,
        message: null,
        loading: true,
      }
    case '@tecnicos/detalhes/GET_SUCCESS':
      return {
        ...state,
        tecnicoSelecionado: action.payload,
        status: 'success',
        loading: false,
      }
    case '@tecnicos/detalhes/GET_ERROR':
      return {
        ...state,
        message: action.message,
        status: 'error',
        loading: false,
      }
    default:
      return state
  }
}

export default tecnicos
