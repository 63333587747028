import styled from 'styled-components'

interface InputValidationProps {
  hasError?: boolean
}

export const Input = styled.input<InputValidationProps>`
  width: 100%;
  padding: 1.8rem 0.75rem;
  margin-bottom: 2.4rem;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? 'var(--color-border-error)' : 'var(--color-border-lighter)'};
  border-radius: 0.4rem;
`

export const InputGroup = styled.div`
  display: flex;
`

export const InputRight = styled.div`
  margin-left: 1rem;
`

export const ClearInput = styled.input`
  background: transparent;
  width: 100%;
  padding: 1.8rem 0.75rem;
  margin-bottom: 2.4rem;
  border: none;
  color: #fff;
  border-bottom: 1px solid var(--color-background);
  ::-webkit-input-placeholder {
    color: var(--color-background);
  }
`

export const PasswordGroup = styled.div``

export const ToggleSpan = styled.span`
  padding: 1.6rem;
  font-size: 1.8rem;
  margin-left: -5.5rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
`
