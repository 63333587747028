import axios from 'axios'

const baseURL = 'https://www.clarogerencial.com.br/api_old/clarotv/'

const claroGerencialApi = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

claroGerencialApi.interceptors.request.use(config => {
  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: 'Token f702dced57a4fa17ec1c08375be52ccf3ebce877',
    },
  }
  return newConfig
})

export default claroGerencialApi
