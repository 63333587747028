import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const drawerWidth = 300
const primaryColor = '#E14B5A'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      background: '#fff',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
      background: primaryColor,
      color: '#FFF',
      display: 'flex',
      alignItems: 'center',
    },
    contentSpacing: { ...theme.mixins.toolbar, minHeight: 56 },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      position: 'relative',
    },
    listItem: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    text: {
      fontSize: 16,
      fontWeight: 400,
    },
    logoutIcon: {
      color: primaryColor,
      fontSize: 22,
    },
    header: {
      color: '#000',
      fontWeight: 600,
    },
    selected: {
      background:
        'linear-gradient(90deg, #FF3800 20%, var(--color-primary) 90%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 62,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
  }),
)

export const LogoImage = styled.img`
  height: 48px;
  margin: 4px 0;
`

export default useStyles
