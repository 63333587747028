import styled from 'styled-components'
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck'
import LeakAddIcon from '@material-ui/icons/LeakAdd'

interface ButtonProps {
  disabled?: boolean
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 90vh;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  background: var(--color-primary-lighter);
  color: var(--color-text-in-primary-background);
`

const HeaderIcon = styled(NetworkCheckIcon)`
  font-size: 22pt;
  margin-right: 0.75rem;
`

const HeaderTitle = styled.h2`
  font-family: Poppins;
  font-weight: 400;
  font-size: 16pt;
`

const Form = styled.form`
  width: 80%;
  margin: 2rem 0;
  & input {
    margin: 0.8rem 0 1.6rem 0;
  }
  @media (min-width: 950px) {
    width: 60%;
  }
`

const Label = styled.label`
  font-size: 11pt;
  font-weight: 500;
`

const Button = styled.div<ButtonProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ disabled }) =>
    disabled ? 'var(--color-text-disabled)' : 'var(--color-primary)'};
  color: var(--color-text-in-primary-background);
  border-radius: 0.75rem;
  margin: 7.5rem 0;
  padding: 2rem 3.5rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
`

const IconRequestSignal = styled(LeakAddIcon)`
  font-size: 22pt;
  font-weight: 600;
  margin-right: 1rem;
`

const RequestSignal = styled.h4`
  font-size: 14pt;
  font-weight: 300;
`

const Signal = {
  Body,
  Header,
  HeaderIcon,
  HeaderTitle,
  Form,
  Label,
  Button,
  IconRequestSignal,
  RequestSignal,
}

export default Signal
