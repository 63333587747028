import styled from 'styled-components'
import { FaSatellite } from 'react-icons/fa'

type IconProps = {
  color?: string
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 85%;
  padding: 1.75rem 1rem;
  margin-top: 2.6rem;
  border: 1px solid var(--color-border-lighter);
  border-radius: 0.45rem;
  box-shadow: 1px 1px 1px var(--color-text-in-white);
  @media (min-width: 900px) {
    width: 65%;
  }
`
const Body = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Icon = styled(FaSatellite)<IconProps>`
  font-size: 20pt;
  margin-bottom: 0.75rem;
  color: ${({ color }) => (color ? `var(${color})` : 'var(--color-default)')};
`

const Status = styled.h5`
  font-family: Roboto;
  font-weight: 700;
  color: ${({ color }) => (color ? `var(${color})` : 'var(--color-default)')};
`

const Details = styled.div``

const Info = styled.h3`
  font-family: Roboto;
  font-weight: 500;
  font-size: 12pt;
  margin: 0.75rem 0;
`
const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Date = styled.span`
  color: var(--color-text-disabled);
  font-size: 10pt;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  border-top: 1px solid var(--color-border-lighter);
  margin-top: 0.75rem;
  padding-top: 0.75rem;
`

const FooterMessage = styled.h5<IconProps>`
  font-family: Roboto;
  font-weight: 700;
  color: ${({ color }) => (color ? `var(${color})` : 'var(--color-default)')};
`

const Button = styled.button<IconProps>`
  cursor: pointer;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 0;
  background: transparent;
  color: ${({ color }) => (color ? `var(${color})` : 'var(--color-default)')};
`

const Item = {
  CardWrapper,
  Body,
  IconWrapper,
  Icon,
  Status,
  Details,
  Info,
  DateWrapper,
  Date,
  Footer,
  FooterMessage,
  Button,
}

export default Item
