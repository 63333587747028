import React, { useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt , faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { ProfileContainer, ProfileCard, CardInfo, TextInfo, ButtonRequest, BoxInfo} from './styles'
import { useSelector } from "react-redux";
import { StoreState } from '../../../store/createStore'
import  profileDefault from '../../../assets/img/avatar.png'
import { Link } from "react-router-dom";


const Profile = () => {
    const { user } = useSelector((store: StoreState) => store)
    const [ userName, setUserName] = useState('')
    const [ userEmail, setUserEmail ] = useState('')
    const [ userCpfCnpj, setUserCpfCnpj ] = useState('')
    const [ userCep, setUserCep ] = useState('')
    const [ userPhoto, setUserPhoto ] = useState('');


useEffect( ()=>{
    setUserName(user.userData.user.name)
    setUserEmail(user.userData.user.email)
    setUserCpfCnpj(user.userData.user.cpf_cnpj)
    setUserCep(user.userData.user.consumidor.cep)
    setUserPhoto(user.userData.user.consumidor.foto || '')
})

    return(
            <ProfileContainer>
                <ProfileCard>

                    <BoxInfo>

                        <CardInfo>
                            <img src={userPhoto || profileDefault} alt='foto de usuário'
                            style={{width:'50px'}} />
                            <TextInfo>{userName}</TextInfo>
                        </CardInfo>

                        <CardInfo>
                            <FontAwesomeIcon style={{fontSize:'50px'}} icon={faAt} />
                            <TextInfo>{userEmail}</TextInfo>
                        </CardInfo>

                        <CardInfo>
                            <TextInfo>*********{userCpfCnpj.substring(8)}</TextInfo>
                        </CardInfo>

                        <CardInfo>
                        <FontAwesomeIcon style={{fontSize:'50px'}} icon={faMapMarkedAlt} />
                        <TextInfo>{userCep}</TextInfo>
                        </CardInfo>

                    </BoxInfo>

                    <CardInfo>
                            <Link to='/app/alterarsenha'>
                                <ButtonRequest >
                                    Alterar senha
                                </ButtonRequest>
                            </Link> 
                    </CardInfo>
                </ProfileCard>
            </ProfileContainer>
    )
}
export default Profile;