import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonProps } from './types'

export const Button = styled.button<ButtonProps>`
  background: ${props =>
    props.inverse ? 'var(--color-background)' : 'var(--color-primary)'};
  border-style: none;
  border-radius: 5rem;
  padding: 2.2rem 0;
  margin: 3.6rem 2.6rem;
  color: ${props =>
    props.inverse
      ? 'var(--color-text-primary)'
      : 'var(--color-text-in-primary-background)'};
  font: 500 1.85rem Roboto;
  box-shadow: rgba(0, 0, 0, 0.25) 2.5px 2.5px 4px;
  cursor: pointer;
`

export const AddButton = styled.button`
  background: var(--color-background);
  border-style: none;
  border-radius: 2.5rem;
  padding: 1.8rem 0.65rem;
  width: 55%;
  margin: 1.4rem 0.4rem;
  color: var(--color-text-primary);
  cursor: pointer;
`

export const SecondaryLink = styled(Link)<ButtonProps>`
  padding: 0.4rem 0 1.8rem 0;
  color: ${props =>
    props.inverse ? '--color-background' : 'var(--color-text-complement)'};

  text-align: right;
  & b {
    font: 500 1.3rem Roboto;
  }
`
