import { action } from 'typesafe-actions'
import { NoticiaResponseProps } from './types.d'

export const getNoticias = () => {
  return action('@noticias/GET')
}

export const getNoticiasSuccess = (response: NoticiaResponseProps[]) => {
  return action('@noticias/GET_SUCCESS', response)
}

export const getNoticiasError = (message: string) => {
  return action('@noticias/GET_ERROR', message)
}
