import Swal, { SweetAlertResult } from 'sweetalert2'

const triggerSuccessAlert = (
  title: string,
  text: string,
  confirmButtonText = 'OK',
  targetMain: boolean,
): Promise<SweetAlertResult<any>> =>
  Swal.fire({
    target: targetMain ? 'main' : 'body',
    customClass: targetMain
      ? {
          container: 'position-absolute',
        }
      : {},
    title,
    text,
    icon: 'success',
    confirmButtonText,
    confirmButtonColor: '#E14B5A',
  })

export default triggerSuccessAlert
