import styled from 'styled-components'
import { ScreenContainer } from '../../components/Containers'

const Container = styled(ScreenContainer)`
  justify-content: space-around;
`

const Form = styled.form`
  padding: 0 1.5rem;
`

const Styled = { Container, Form }

export default Styled
