import * as yup from 'yup'

const schema = yup.object().shape({
  nome: yup
    .string()
    .min(2, 'Nome deve conter pelo menos 2 caracteres')
    .required('Nome é obrigatório'),
  sobrenome: yup
    .string()
    .min(2, 'Sobrenome deve conter pelo menos 2 caracteres')
    .required('Sobrenome é obrigatório'),
  cpf_cnpj: yup
    .string()
    .matches(
      /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/,
      'Formato inválido de CPF/CNPJ',
    )
    .required('Digite seu CPF'),
  cep: yup
    .string()
    .min(8, 'Cep Inválido')
    .max(9, 'Cep Inválido')
    .required('Digite seu CEP'),
  numero: yup.string(),
  senha: yup
    .string()
    .min(6, 'Sua senha deve conter pelo menos 6 caracteres')
    .required('Digite sua senha'),
  senha_confirm: yup
    .string()
    .oneOf([yup.ref('senha')], 'Senhas não correspondem')
    .required('Confirme sua Senha'),
})

export default schema
