import { UserState, UserAction } from './types'

const initialState: UserState = {
  userData: {
    Token: '',
    user: {
      cpf_cnpj: '',
      name: '',
      username: '',
      email: '',
      tipo_consumidor: true,
      consumidor: {
        id: 0,
        foto: null,
        nome: '',
        sobrenome: '',
        cep: '',

      },
    },
  },
  loading: false,
  status: '',
  message: null,
}

const user = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case '@user/SIGN_IN':
      return {
        ...initialState,
        loading: true,
      }
    case '@user/SIGN_IN_SUCCESS':
      return {
        ...state,
        loading: false,
        status: 'success',
        userData: action.payload,
      }
    case '@user/SIGN_IN_ERROR':
      return {
        ...state,
        loading: false,
        status: 'error',
        message: action.payload,
      }
    case '@user/SIGN_IN_RESET_STATUS':
      return {
        ...state,
        status: '',
        message: null,
      }
    case '@user/LOGOUT':
      return initialState
    case '@user/SIGNUP':
      return {
        ...state,
        loading: true,
      }
    case '@user/SIGNUP_SUCCESS':
      return {
        ...state,
        loading: false,
        status: 'success',
      }
    case '@user/SIGNUP_ERROR':
      return {
        ...state,
        loading: false,
        status: 'error',
        message: action.payload,
      }
    case '@user/SIGNUP_RESET_STATUS':
      return initialState
    default:
      return state
  }
}

export default user
