import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { useTheme } from '@material-ui/core/styles'

import {
  BrowserRouter as Router,
  Switch,
  Link,
  Redirect,
  Route,
} from 'react-router-dom'

import DetailsTechnicians from '../../pages/App/SearchTechnicians/DetailsTechnicians'
import logo from '../../assets/img/banner.png'
import DrawerContent from './DrawerContent'
import menuRoutes from './menuRoutes'
import useStyles, { LogoImage } from './styles'
import RegisterDevice from '../../pages/App/MyDevices/RegisterDevice'
import SignalRequest from '../../pages/App/Home/SignalReinforcement/SignalRequest'
import ChangePassword from '../../pages/ChangePassword'

const primaryColor = '#E14B5A'

const ResponsiveDrawer: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Router>
      <Redirect to="/app/home" />
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Abrir menu lateral"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon style={{ fontSize: 32, color: primaryColor }} />
            </IconButton>
            <Link to="/app/home">
              <LogoImage src={logo} alt="Logo Claro TV Pré-Pago" />
            </Link>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <DrawerContent />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <DrawerContent />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.contentSpacing} />
          <Switch>
            {menuRoutes.map(
              ({ name, path, RouteComponent }) =>
                path && (
                  <Route key={name} exact path={path}>
                    <RouteComponent />
                  </Route>
                ),
            )}
            <Route
              path="/app/tecnicos/detalhes"
              component={DetailsTechnicians}
            />
            <Route
              path="/app/dispositivos/cadastro"
              component={RegisterDevice}
            />
            <Route
              path="/app/reforco_de_sinal/solicitar"
              component={SignalRequest}
            />
            <Route path="/app/alterarsenha" component={ChangePassword}/>
          </Switch>
        </main>
      </div>
    </Router>
  )
}

export default ResponsiveDrawer
