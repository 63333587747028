import styled from 'styled-components'
import { IoClose } from 'react-icons/io5'

const SearchForm = styled.form`
  display: flex;
  border-bottom: 1px solid var(--color-border-lighter);
`

const IconClose = styled(IoClose)`
  cursor: pointer;
  font-size: 24pt;
  color: var(--color-icons-secondary);
  margin: 1.2rem;
  padding-right: 0.75rem;
  border-right: 1px solid var(--color-border-lighter);
`

const Input = styled.input`
  width: 100%;
  background: transparent;
  border: 0;
`

const CepAddressContainer = styled.div`
  padding: 0.75rem;
  border-bottom: 1px solid var(--color-border-lighter);
`

const CepAddressMessage = styled.h3`
  margin: 20px;
  font-size: 14pt;
  font-weight: 500;
  text-align: center;
  color: var(--color-text-in-background);
`

const CepAddress = styled.p`
  margin: 20px;
  text-align: center;
  color: var(--color-text-in-background);
`

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TechList = styled.div`
  min-height: calc(100vh - 60px);
`

const LoadingWrapper = styled.div`
  margin: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NotFoundMessage = styled.h3`
  font-weight: 500;
  margin: 46px;
  text-align: center;
  color: var(--color-text-in-background);
`
const ContainerNotConsumidor = styled.div`
  display: flex;
  height: 300px;
  width: 80%;
  background-color: #d00000;
  margin-top: 10%;
  margin-left: 10%;
  border-radius: 8px;
  align-items: center;
  justify-content: center;

`
const TittleAcess = styled.h5`
  color: #ffffff;
`

const Styled = {
  SearchForm,
  IconClose,
  Input,
  CepAddressContainer,
  CepAddressMessage,
  CepAddress,
  Loader,
  TechList,
  LoadingWrapper,
  NotFoundMessage,
  ContainerNotConsumidor,
  TittleAcess
}

export default Styled
