import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getDetalhesTecnico } from '../../store/modules/tecnicos/actions'
import ProfilePlaceholder from '../../assets/img/avatar.png'
import { TecnicosData } from '../../store/modules/tecnicos/types'
import ListItem from './styles'

interface TechListProps {
  data: TecnicosData
}

const TechListItem: React.FC<TechListProps> = ({ data }) => {
  
  const dispatch = useDispatch()
  const history = useHistory()
  const handleClick = () => {
    dispatch(getDetalhesTecnico({ chave: data.cpf_cnpj }))
    history.push('/app/tecnicos/detalhes')
  }

  return (
    <ListItem.Container onClick={() => handleClick()}>
      <ListItem.AvatarWrapper>
        <ListItem.Avatar imgUrl={ProfilePlaceholder} />
      </ListItem.AvatarWrapper>
      <ListItem.NameWrapper>
        <ListItem.Name>{`${data.nome} ${data.sobrenome}`}</ListItem.Name>
      </ListItem.NameWrapper>
      <ListItem.IconContainer>
        <ListItem.IconArrow />
      </ListItem.IconContainer>
    </ListItem.Container>
  )
}

export default TechListItem
