import React from 'react'
import { SubContainer, SubScreen } from '../../../components/Containers'
import Device from './styles'

const MyDevices: React.FC = () => {
  
  return (
    <SubScreen>
      <SubContainer>
        <Device.Container>
          <Device.MessageDefault>
            Nenhum dispositivo Instalado
          </Device.MessageDefault>
          <Device.Button to="/app/dispositivos/cadastro">
            <Device.IconAddDevice />
            <Device.AddDevice>Adicionar Equipamento</Device.AddDevice>
          </Device.Button>
        </Device.Container>
      </SubContainer>
    </SubScreen>
  )
}

export default MyDevices
