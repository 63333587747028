import { takeLatest, call, put, all } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import api from '../../../services/api'

export function* getNoticias(action: ActionType<typeof actions.getNoticias>) {
  try {
    const { data } = yield call(api.get, `/feeds/obter_publicacoes_feed/`)
    yield put(actions.getNoticiasSuccess(data))
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || 'Falha inesperada ao Obter Notícias'
    yield put(actions.getNoticiasError(errorMessage))
  }
}

export default all([takeLatest('@noticias/GET', getNoticias)])
