import { takeLatest, call, put, all } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import api from '../../../services/api'

export function* getTecnicos(action: ActionType<typeof actions.getTecnicos>) {
  try {
    const { cep } = action.payload
    const { data } = yield call(api.get, '/tecnicos/obter_tecnicos_por_cep/', {
      params: { cep },
    })
    yield put(actions.getTecnicosSuccess(data))
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || 'Falha inesperada ao Obter Técnicos'
    yield put(actions.getTecnicosError(errorMessage))
  }
}

export function* getDetalhesTecnico(
  action: ActionType<typeof actions.getDetalhesTecnico>,
) {
  try {
    const { chave } = action.payload
    const { data } = yield call(api.get, '/tecnicos/consultar_tecnico', {
      params: { chave },
    })
    yield put(actions.getDetalhesTecnicoSuccess(data))
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || 'Falha inesperada ao Obter Técnicos'
    yield put(actions.getDetalhesTecnicoError(errorMessage))
  }
}

export default all([
  takeLatest('@tecnicos/GET', getTecnicos),
  takeLatest('@tecnicos/detalhes/GET', getDetalhesTecnico),
])
