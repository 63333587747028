import { action } from 'typesafe-actions'
import { ReforcoSinalProps } from './types.d'

type SignalRequestProp = {
  CPF: string
  CAID: string
  SCID: string
}

type cpfSignalProp = {
  cpf: string
}
export const getReforcoSinal = (cpf: cpfSignalProp) =>
  action('@reforco_sinal/GET', cpf)

export const getReforcoSinalSuccess = (data: ReforcoSinalProps[]) =>
  action('@reforco_sinal/GET_SUCCESS', data)

export const getReforcoSinalError = (message: string) =>
  action('@reforco_sinal/GET_ERROR', { message })

export const createReforcoSinal = (payload: SignalRequestProp) =>
  action('@reforco_sinal/POST', payload)

export const createReforcoSinalSuccess = (data: ReforcoSinalProps) =>
  action('@reforco_sinal/POST_SUCCESS', data)

export const createReforcoSinalError = (message: string) =>
  action('@reforco_sinal/POST_ERROR', { message })

export const clearReforcoSinal = () => action('@reforco_sinal/CLEAR')
