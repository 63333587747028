import styled from 'styled-components'

interface ImgProps {
  imgUrl: string
}

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem;
`

const Avatar = styled.div<ImgProps>`
  background-image: url(${({ imgUrl }) => imgUrl});
  background-position: center;
  background-size: cover;
  width: 6rem;
  height: 5rem;
  padding: 1rem;
  border-radius: 500rem;
`
const AvatarCons = styled.img`
  width: 6rem;
  height: 5rem;
  padding: 1rem;
  border-radius: 500rem;

`

const Name = styled.h3`
  width: 90%;
  padding-left: 1.2rem;
  //font-family: Poppins;
  font-weight: 300;
  font-size: 20px;
`

const Content = {
  AvatarWrapper,
  Avatar,
  AvatarCons,
  Name,
}

export default Content
