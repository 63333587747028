import React from "react";
import { Container, Tittle, Message, LogoContainer, LogoSelf, GoSignin, Logout } from './styles'
import logo from '../../assets/img/banner.png'
import { logout } from "../../utils/auth";
import { Link, useHistory } from 'react-router-dom'


const RedirectAcess: React.FC = () => {

    const history = useHistory()

    return(
        <Container>
            <LogoContainer>
                <LogoSelf src={logo} alt="logo claro tv"/>
            </LogoContainer>
            <Tittle>CPF não cadastrado.</Tittle> 
            <Message>Realize o cadastro para acessar o sistema.</Message>
            <Link to='/cadastro'><GoSignin>Cadastre-se</GoSignin></Link>
            <Logout 
                onClick={() => {
                logout()
                setTimeout(() => history.push('/login'), 500)
                setTimeout(() => document.location.reload(), 500)
                  }} >
                    Login
            </Logout>        
        </Container>
    )
}

export default RedirectAcess;