import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getReforcoSinal,
  createReforcoSinal,
  clearReforcoSinal,
} from '../../../../../store/modules/reforcoSinal/actions'
import { StoreState } from '../../../../../store/createStore'
import { SubContainer, SubScreen } from '../../../../../components/Containers'
import { Input } from '../../../../../components/Form/Inputs'
import {
  maskCpfCnpj,
  validateCpfCnpj,
  maskCaidScid,
  validateCaidScid,
} from '../../../../../utils'
import Signal from './styles'
import triggerSuccessAlert from '../../../../../utils/alerts/success'
import triggerErrorAlert from '../../../../../utils/alerts/error'

const SignalRequest: React.FC = () => {
  const { user } = useSelector((store: StoreState) => store.user.userData)
  const { reforcoSinal } = useSelector((store: StoreState) => store)
  const dispatch = useDispatch()
  const history = useHistory()
  const [cpf, setCPF] = useState(maskCpfCnpj(user.cpf_cnpj))
  const [errorCPF, setErrorCPF] = useState(false)
  const [caid, setCAID] = useState('')
  const [errorCAID, setErrorCAID] = useState(false)
  const [scid, setSCID] = useState('')
  const [errorSCID, setErrorSCID] = useState(false)

  const handleCpf = (inputValue: string) => {
    const maskedCpfCnpj = maskCpfCnpj(inputValue)
    setCPF(maskedCpfCnpj)
    if (validateCpfCnpj(maskedCpfCnpj)) {
      setErrorCPF(false)
    } else {
      setErrorCPF(true)
    }
  }

  const handleCaidScid = (inputValue: string, type: 'caid' | 'scid') => {
    if (inputValue.length <= 14) {
      const maskedCaidScid = maskCaidScid(inputValue)
      if (type === 'caid') {
        setCAID(maskedCaidScid)
        if (validateCaidScid(maskedCaidScid)) {
          setErrorCAID(false)
        } else {
          setErrorCAID(true)
        }
      } else {
        setSCID(maskedCaidScid)
        if (validateCaidScid(maskedCaidScid)) {
          setErrorSCID(false)
        } else {
          setErrorSCID(true)
        }
      }
    }
  }

  const submitSignalRequest = async () => {
    const CPF = cpf.replace(/[/.-]/g, '')
    const CAID = caid.replace(/ /g, '')
    const SCID = scid.replace(/ /g, '')
    const payload = {
      CPF,
      CAID,
      SCID,
    }
    dispatch(createReforcoSinal(payload))
  }

  const fireSuccessAlert = useCallback(async () => {
    const { isConfirmed } = await triggerSuccessAlert(
      'Requisição Realizada com Sucesso',
      'Sua Solicitação foi enviada e será respondida em breve',
      'OK',
      true,
    )
    if (isConfirmed) {
      dispatch(clearReforcoSinal())
      dispatch(getReforcoSinal({ cpf: user.cpf_cnpj }))
      history.push('/app/reforco_de_sinal')
    }
  }, [dispatch, history, user.cpf_cnpj])

  const fireErrorAlert = useCallback(async () => {
    const { isConfirmed } = await triggerErrorAlert(
      'Falha na Requisição da Solicitação',
      'Verifique se as informações foram digitadas corretamente e tente novamente',
      'OK',
      true,
    )
    if (isConfirmed) {
      dispatch(clearReforcoSinal())
    }
  }, [dispatch])

  useEffect(() => {
    if (reforcoSinal.statusRequest === 'success') {
      fireSuccessAlert()
    }
    if (reforcoSinal.statusRequest === 'error') {
      fireErrorAlert()
    }
  }, [reforcoSinal.statusRequest, fireSuccessAlert, fireErrorAlert])

  return (
    <SubScreen>
      <SubContainer>
        <Signal.Header>
          <Signal.HeaderIcon />
          <Signal.HeaderTitle>Solicitar Reforço de Sinal</Signal.HeaderTitle>
        </Signal.Header>
        <Signal.Body>
          <Signal.Form>
            <Signal.Label htmlFor="CPF/CNPJ">CPF/CNPJ</Signal.Label>
            <Input
              type="text"
              name="CPF/CNPJ"
              placeholder="123.456.789-10"
              value={cpf}
              onChange={e => handleCpf(e.target.value)}
              hasError={errorCPF}
              pattern="(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)"
            />
            <Signal.Label htmlFor="CAID">CAID</Signal.Label>
            <Input
              type="text"
              name="CAID"
              placeholder="1234 5678 9101"
              value={caid}
              onChange={e => handleCaidScid(e.target.value, 'caid')}
              hasError={errorCAID}
            />
            <Signal.Label htmlFor="SCID">SCID</Signal.Label>
            <Input
              type="text"
              name="SCID"
              placeholder="1234 5678 9101"
              value={scid}
              onChange={e => handleCaidScid(e.target.value, 'scid')}
              hasError={errorSCID}
            />
            <Signal.Button
              disabled={
                errorCPF ||
                errorCAID ||
                errorSCID ||
                cpf === '' ||
                caid === '' ||
                scid === ''
              }
              onClick={() =>
                !errorCPF &&
                !errorCAID &&
                !errorSCID &&
                cpf !== '' &&
                caid !== '' &&
                scid !== '' &&
                submitSignalRequest()
              }
            >
              <Signal.IconRequestSignal />
              <Signal.RequestSignal>Solicitar Reforço</Signal.RequestSignal>
            </Signal.Button>
          </Signal.Form>
        </Signal.Body>
      </SubContainer>
    </SubScreen>
  )
}

export default SignalRequest
