import React, { useState, useEffect } from 'react'
import { BounceLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { SubContainer, SubScreen } from '../../../../components/Containers'
import { StoreState } from '../../../../store/createStore'
import { getReforcoSinal } from '../../../../store/modules/reforcoSinal/actions'
import { maskCpfCnpj, unmaskCpfCnpj } from '../../../../utils'
import RequestItem from './components/RequestItem'
import Signal from './styles'

const SignalReinforcement: React.FC = () => {
  const { reforcoSinal, user } = useSelector((store: StoreState) => store)
  const dispatch = useDispatch()
  const [cpf, setCpf] = useState(maskCpfCnpj(user.userData.user.cpf_cnpj))

  const handleChangeCpfCnpj = (value: string) => {
    const maskedCpfCnpj = maskCpfCnpj(value)
    if (
      maskedCpfCnpj !== value.slice(0, -1) &&
      (maskedCpfCnpj.length === 14 || maskedCpfCnpj.length === 18)
    ) {
      dispatch(getReforcoSinal({ cpf: unmaskCpfCnpj(maskedCpfCnpj) }))
    }
    setCpf(maskedCpfCnpj)
  }

  useEffect(() => {
    dispatch(getReforcoSinal({ cpf: user.userData.user.cpf_cnpj }))
  }, [dispatch, user.userData.user.cpf_cnpj])
  
  return (
    <SubScreen>
      <SubContainer>
        <Signal.Header>
          <Signal.HeaderIcon />
          <Signal.HeaderTitle>
            Solicitações de Reforço de Sinal
          </Signal.HeaderTitle>
        </Signal.Header>
        <Signal.SearchForm>
          <Signal.IconClose onClick={() => console.log('')} />
          <Signal.Input
            placeholder="Busque solicitações por CPF/CNPJ"
            value={cpf}
            onChange={e => handleChangeCpfCnpj(e.target.value)}
          />
        </Signal.SearchForm>
        <Signal.Container>
          {reforcoSinal.loading ? (
            <Signal.LoadingWrapper>
              <BounceLoader color="#FD7E77" size={60} />
            </Signal.LoadingWrapper>
          ) : (
            <Signal.List>
              {reforcoSinal.data.length > 0 ? (
                reforcoSinal.data.map(item => (
                  <RequestItem key={item.id} item={item} />
                ))
              ) : (
                <Signal.MessageDefault>
                  Nenhuma Solicitação.
                </Signal.MessageDefault>
              )}
            </Signal.List>
          )}
          <Signal.Button to="/app/reforco_de_sinal/solicitar">
            <Signal.IconAddSignal />
            <Signal.AddSignal>Solicitar Reforço de Sinal</Signal.AddSignal>
          </Signal.Button>
        </Signal.Container>
      </SubContainer>
    </SubScreen>
  )
}

export default SignalReinforcement
