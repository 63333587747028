import styled from "styled-components";

export const Container = styled.div`
    background-color: transparent;
    width: 80%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
    border-radius: 8px;
    flex-direction: column;
    position: relative;
    gap: 20px;
    @media (max-width: 820px) {
        height: 500px;
        margin-top: 30%;
        width: 100%;
    }
`
export const Tittle = styled.h5`
    font-weight: bold;
    @media (max-width: 820px) {
        font-size: 20px;
    }
`
export const Message = styled.p`
    font-size: 18px;
    @media (max-width: 820px) {
        font-size: 20px;
    }
    @media (max-width: 475px) {
        font-size: 18px;
    }
    @media (max-width: 280px) {
        font-size: 12px;
    }
`
export const LogoContainer = styled.div`
    width: 125px;
    height: 70px;
`
export const LogoSelf = styled.img`
     width: 100%;
    height: 100%;
`
export const GoSignin = styled.button`
    color: #d00000;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-top: 50px;
    font-size: 20px;
    @media (max-width: 820px) {
        font-size: 20px;
    }
    @media (max-width: 280px) {
        font-size: 12px;
    }
    &:hover{
        color: #da0000;
        text-decoration: underline;
    }
`
export const Logout = styled.button`
    border:none;
    background-color: transparent;
   
    &:hover{
        cursor:pointer;
        color:blue;
    }

`
