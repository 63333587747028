/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { BeatLoader } from 'react-spinners'
import { withStyles } from '@material-ui/core/styles'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Switch from '@material-ui/core/Switch'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schemaValidation from './validations'
import { StoreState } from '../../store/createStore'
import {signUp, signUpError, signUpResetStatus} from '../../store/modules/user/actions'
import banner from '../../assets/img/banner.png'
import {
  InputGroup,
  InputRight,
  ClearInput,
  ToggleSpan,
  PasswordGroup,
} from '../../components/Form/Inputs'
import { Button, AddButton, SecondaryLink } from '../../components/Buttons'
import { FormTitle, FormSubtitle } from '../../components/Text'
import {
  ScreenContainer,
  LogoContainer,
  FormContainer,
  FormHeader,
  FormCard,
  FormScrollable,
} from '../../components/Containers'
import ValidationMessage from '../../components/Form/ValidationMessage'
import { maskPhone, validatePhone } from '../../utils/masks/phone'
import { maskCpfCnpj, unmaskCpfCnpj } from '../../utils'

const PhoneSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#19BC75',
    },
    '&$checked + $track': {
      backgroundColor: '#009421',
    },
  },
  checked: {},
  track: {},
})(Switch)

const Signup: React.FC = () => {

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
  })
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state: StoreState) => state)
  const [telefones, setTelefones] = useState([
    {
      telefone: '',
      flag_principal: true,
    },
  ])
  const [erroTelefones, setErroTelefones] = useState<string | null>(null)
  const [emails, setEmails] = useState([
    {
      email: '',
      flag_principal: true,
    },
  ])
  const [erroEmails, setErroEmails] = useState<string | null>(null)
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [passwordConfirmVisibility, setPasswordConfirmVisibility] = useState(false)
  const handlePhone = (index: number, phoneValue: string) => {
    setErroTelefones(null)
    const newPhonesList = [...telefones]
    const maskedPhone = maskPhone(phoneValue)
    newPhonesList[index].telefone = maskedPhone
    setTelefones(newPhonesList)
  }

  const addPhone = () => {
    const newPhone = {
      telefone: '',
      flag_principal: false,
    }
    setTelefones([...telefones, newPhone])
  }

  const setMainPhone = (index: number) => {
    const newPhonesList = telefones.map((phone, phoneIndex) => {
      if (phoneIndex !== index) {
        return {
          telefone: phone.telefone,
          flag_principal: false,
        }
      }
      return phone
    })
    newPhonesList[index].flag_principal = !newPhonesList[index].flag_principal
    setTelefones(newPhonesList)
  }

  const handleEmail = (index: number, emailValue: string) => {
    setErroEmails(null)
    const newEmailsList = [...emails]
    newEmailsList[index].email = emailValue
    setEmails(newEmailsList)
  }

  const addEmail = () => {
    const newEmail = {
      email: '',
      flag_principal: false,
    }
    setEmails([...emails, newEmail])
  }

  const submitForm = (data: any) => {
    if (!validatePhone(telefones[0].telefone)) {
      return setErroTelefones('Telefone Inválido')
    }
    if (emails[0].email === '') {
      return setErroEmails('Email Inválido')
    }
    const sanitizedPhones = telefones.filter(item =>
      validatePhone(item.telefone),
    )
    const sanitizedMails = emails.filter(item => item.email !== '')
    const payload = {
      ...data,
      sem_numero: data.numero === '',
      cpf_cnpj: unmaskCpfCnpj(data.cpf_cnpj),
      telefones: sanitizedPhones,
      emails: sanitizedMails,
    }
    dispatch(signUp(payload))
  }

  useEffect(() => {
    if (user.status === 'error') {
      toast.error(`${user.message}`)
      dispatch(signUpResetStatus())
    }
    if (user.status === 'success') {
      dispatch(signUpResetStatus())
      toast.success('Cliente Cadastrado com Sucesso!')
      setTimeout(() => history.push('/login'), 3000)
    }
  }, [user, dispatch])


  return (
    <ScreenContainer inverse>
      <LogoContainer hideMobile>
        <img src={banner} alt="Claro TV Pré-Pago" />
      </LogoContainer>
      <FormContainer>
        <FormCard>
          <FormHeader>
            <FormTitle showMobile>CADASTRE-SE</FormTitle>
            <FormSubtitle showMobile>
              Novo no Claro TV? Cadastre-se para habilitar seu aparelho e
              aproveitar todos os conteúdos!
            </FormSubtitle>
          </FormHeader>
          <FormScrollable>
            <form onSubmit={handleSubmit(submitForm)}>
              <fieldset>
                <ClearInput
                  type="text"
                  placeholder="Primeiro Nome"
                  {...register('nome')}
                />
                <ValidationMessage secondary>
                  {errors.nome?.message}
                </ValidationMessage>
                <ClearInput
                  type="text"
                  placeholder="Sobrenome"
                  {...register('sobrenome')}
                />
                <ValidationMessage secondary>
                  {errors.sobrenome?.message}
                </ValidationMessage>
                <Controller
                  name="cpf_cnpj"
                  control={control}
                  render={({ field }) => (
                    <ClearInput
                      type="tel"
                      placeholder="CPF ou CNPJ"
                      value={field.value}
                      onChange={({ target }) =>
                        field.onChange(maskCpfCnpj(target.value))
                      }
                    />
                  )}
                />

                <ValidationMessage secondary>
                  {errors.cpf_cnpj?.message}
                </ValidationMessage>
                <ClearInput type="tel" placeholder="CEP" {...register('cep')} />
                <ValidationMessage secondary>
                  {errors.cep?.message}
                </ValidationMessage>
                <ClearInput
                  type="number"
                  placeholder="Nº da Casa"
                  {...register('numero')}
                />
                <ValidationMessage secondary>
                  {errors.numero?.message}
                </ValidationMessage>
                {telefones.map((item, index) => (
                  <InputGroup key={index}>
                    <ClearInput
                      type="tel"
                      name="telefone"
                      placeholder="Telefone"
                      value={item.telefone}
                      onChange={e => handlePhone(index, e.target.value)}
                    />
                    <InputRight>
                      <PhoneSwitch
                        checked={item.flag_principal}
                        onChange={() => setMainPhone(index)}
                        name="check_main_phone"
                        inputProps={{
                          'aria-label': 'Marcar telefone principal',
                        }}
                      />
                      <p>Principal?</p>
                    </InputRight>
                  </InputGroup>
                ))}
                <ValidationMessage secondary>{erroTelefones}</ValidationMessage>
                <AddButton type="button" onClick={() => addPhone()}>
                  + Telefone Adicional
                </AddButton>
                {emails.map((item, index) => (
                  <ClearInput
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={item.email}
                    onChange={e => handleEmail(index, e.target.value)}
                  />
                ))}
                <ValidationMessage secondary>{erroEmails}</ValidationMessage>
                <AddButton type="button" onClick={() => addEmail()}>
                  + Email Adicional
                </AddButton>
                <PasswordGroup>
                  <ClearInput
                    type={passwordVisibility ? 'text' : 'password'}
                    placeholder="Senha"
                    {...register('senha')}
                  />
                  <ToggleSpan
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ToggleSpan>
                </PasswordGroup>
                <ValidationMessage secondary>
                  {errors.senha?.message}
                </ValidationMessage>
                <PasswordGroup>
                  <ClearInput
                    type={passwordConfirmVisibility ? 'text' : 'password'}
                    placeholder="Confirme a Senha"
                    {...register('senha_confirm')}
                  />
                  <ToggleSpan
                    onClick={() =>
                      setPasswordConfirmVisibility(!passwordConfirmVisibility)
                    }
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ToggleSpan>
                </PasswordGroup>
                <ValidationMessage secondary>
                  {errors.senha_confirm?.message}
                </ValidationMessage>
              </fieldset>
              {true ? (
                <Button inverse type="submit">
                  CADASTRAR
                </Button>
              ) : (
                <Button inverse type="button">
                  <BeatLoader color="#FFF" size={10} />
                </Button>
              )}
            </form>
            <footer>
              <SecondaryLink inverse to="/login">
                Já Possui Cadastro? <b>Login</b>
              </SecondaryLink>
            </footer>
          </FormScrollable>
        </FormCard>
      </FormContainer>
      <ToastContainer toastStyle={{fontSize: '15px'}} />
    </ScreenContainer>
  )
}

export default Signup
