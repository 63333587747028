export const maskCpfCnpj = (rawNumber: string): string => {
  if (rawNumber.length <= 14) {
    const formatCpf = rawNumber
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
    return formatCpf
  }
  const formatCnpj = rawNumber
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
  return formatCnpj
}

export const unmaskCpfCnpj = (maskedValue: string): string => {
  const unmaskedInput = maskedValue.replace(/[/.-]/g, '')
  return unmaskedInput
}

export const validateCpfCnpj = (documentNumber: string): boolean => {
  const matchCpfCnpj =
    /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/
  return matchCpfCnpj.test(documentNumber)
}

export const maskCaidScid = (rawValue: string): string => {
  if (rawValue.length <= 14) {
    const formatCpf = rawValue
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
    return formatCpf
  }
  return rawValue
}

export const validateCaidScid = (rawValue: string): boolean => {
  const matchCaidScid = /(^\d{4} \d{4} \d{4}$)/
  return matchCaidScid.test(rawValue)
}
