import { action } from 'typesafe-actions'
import { purgeAllState } from '../..'
import { UserRequestProps, UserState } from './types'

export const signIn = ({ username, password }: UserRequestProps) => {
  const userPayload = {
    username,
    password,
    app: 1,
  }
  return action('@user/SIGN_IN', userPayload)
}

export const signInSuccess = (userResponse: UserState['userData']) => {
  localStorage.setItem(
    '$2y$12$2FQiAF3LwQkymP5on08B4eyXs7EOVcKvELd876Va0Vj3cUuoBMG56',
    `Token ${userResponse.Token}`,
  )
  return action('@user/SIGN_IN_SUCCESS', userResponse)
}

export const signInError = (message: string) => {
  return action('@user/SIGN_IN_ERROR', message)
}

export const signInResetStatus = () => {
  return action('@user/SIGN_IN_RESET_STATUS')
}

export const logout = () => {
  localStorage.removeItem(
    '$2y$12$2FQiAF3LwQkymP5on08B4eyXs7EOVcKvELd876Va0Vj3cUuoBMG56',
  )
  purgeAllState()
  return action('@user/LOGOUT')
}

export const signUp = (payload: any) => {
  return action('@user/SIGNUP', payload)
}

export const signUpSuccess = (registerResponse: any) => {
  return action('@user/SIGNUP_SUCCESS', registerResponse)
}

export const signUpError = (message: string) => {
  return action('@user/SIGNUP_ERROR', message)
}

export const signUpResetStatus = () => {
  return action('@user/SIGNUP_RESET_STATUS')
}
