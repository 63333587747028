import styled from 'styled-components'
import { MdAddToQueue } from 'react-icons/md'
import { Link } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 90vh;
`

const MessageDefault = styled.h3`
  width: 100%;
  border-top: 1px solid var(--color-border-lighter);
  border-bottom: 1px solid var(--color-border-lighter);
  margin: 4rem 0;
  padding: 4rem 0;
  text-align: center;
  color: var(--color-text-in-background);
  font-size: 16pt;
`

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-primary);
  color: var(--color-text-in-primary-background);
  border-radius: 0.75rem;
  margin-bottom: 7.5rem;
  padding: 2rem 3.5rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
`

const IconAddDevice = styled(MdAddToQueue)`
  font-size: 22pt;
  font-weight: 600;
  margin-right: 1rem;
`

const AddDevice = styled.h4`
  font-size: 14pt;
  font-weight: 300;
`

const Device = {
  Container,
  MessageDefault,
  Button,
  IconAddDevice,
  AddDevice,
}

export default Device
