import { combineReducers } from 'redux'

import user from './user/reducer'
import noticias from './noticias/reducer'
import tecnicos from './tecnicos/reducer'
import reforcoSinal from './reforcoSinal/reducer'

import { StoreState } from '../createStore'

export default combineReducers<StoreState>({
  user,
  noticias,
  tecnicos,
  reforcoSinal,
})
