import React, { useState } from 'react'
import Lottie from 'react-lottie'
import triggerMaintenanceAlert from '../../../../utils/alerts/maintenance'
import { SubContainer, SubScreen } from '../../../../components/Containers'
import standAloneAdd from '../../../../assets/animation/standalone-add.json'
import deliverAdd from '../../../../assets/animation/deliver-add.json'
import Device from './styles'

const RegisterDevice: React.FC = () => {
  
  const [selectedDeliver, setSelectedDeliver] = useState(false)
  const [selectedStandalone, setSelectedStandalone] = useState(false)
  return (
    <SubScreen>
      <SubContainer>
        <Device.Container>
          <Device.SelectWrapper>
            <Device.Title>Selecione como deseja fazer a ativação</Device.Title>
            <Device.ButtonsContainer>
              <Device.ButtonOption
                selected={selectedDeliver}
                onClick={() => {
                  if (selectedStandalone && !selectedDeliver) {
                    setSelectedStandalone(false)
                  }
                  setSelectedDeliver(!selectedDeliver)
                }}
              >
                <Lottie
                  options={{
                    animationData: deliverAdd,
                  }}
                  height={150}
                  width={150}
                />
                <Device.OptionTitle>
                  Contactar Técnico para ativação
                </Device.OptionTitle>
              </Device.ButtonOption>
              <Device.ButtonOption
                selected={selectedStandalone}
                onClick={() => {
                  if (selectedDeliver && !selectedStandalone) {
                    setSelectedDeliver(false)
                  }
                  setSelectedStandalone(!selectedStandalone)
                }}
              >
                <Lottie
                  options={{
                    animationData: standAloneAdd,
                  }}
                  height={150}
                  width={150}
                />
                <Device.OptionTitle>
                  Solicitar ativação pelo app
                </Device.OptionTitle>
              </Device.ButtonOption>
            </Device.ButtonsContainer>
            <Device.Description>
              {selectedDeliver &&
                'Escolha manualmente dentre nossos técnicos disponíveis por telefone e realize o pagamento pessoalmente.'}
              {selectedStandalone &&
                'Realize o pagamento e o agendamento diretamente pelo aplicativo e um técnico será enviado para sua casa'}
              {!selectedDeliver &&
                !selectedStandalone &&
                'Selecione uma opção para mais detalhes'}
            </Device.Description>
            {selectedDeliver && (
              <Device.LinkSubmit to="/app/tecnicos/buscar">
                <p>Próximo</p> <Device.ArrowIcon />
              </Device.LinkSubmit>
            )}
            {selectedStandalone && (
              <Device.LinkSubmit
                to="#"
                onClick={() => triggerMaintenanceAlert(true)}
              >
                <p>Próximo</p> <Device.ArrowIcon />
              </Device.LinkSubmit>
            )}
            {!selectedStandalone && !selectedDeliver && (
              <Device.ButtonSubmit>Próximo</Device.ButtonSubmit>
            )}
          </Device.SelectWrapper>
        </Device.Container>
      </SubContainer>
    </SubScreen>
  )
}

export default RegisterDevice
