import React from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import isAuthenticated from '../utils/auth'

import Login from '../pages/Login'
import Signup from '../pages/Signup'
import  RequestNewpassord from '../pages/App/RequestNewPassword'
import PrivateRoute from './PrivateRoute'
import Navbar from '../components/Navbar'

import RedirectAcess from '../pages/DeniedAcess'

const Routes: React.FC = () => {
  
  return (
    <BrowserRouter>
      <Route
        path="/login"
        exact
        render={({ location }) =>
          isAuthenticated() === true ? (
            <Redirect
              to={{
                pathname: '/app',
                state: { from: location },
              }}
            />
          ) : (
            <Login />  
          )
        }
      />
      <Route path="/permissao" component={RedirectAcess} exact />
      <Route path="/cadastro" component={Signup} exact />
      <Route path="/solicitar" component={RequestNewpassord} exact />
      
      <PrivateRoute path="/app">
        <Navbar />
      </PrivateRoute>
      <Redirect path="*" to="/login" />
    </BrowserRouter>
  )
}

export default Routes
