import styled from 'styled-components'
import { FormProps, HideMobileProps } from './types'
import LoginBG from '../../assets/img/login-bg.jpg'

export const ScreenContainer = styled.div<FormProps>`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${props =>
    props.inverse ? 'var(--color-primary)' : 'var(--color-background)'};
  color: ${props =>
    props.inverse
      ? 'var(--color-text-in-primary-background)'
      : 'var(--color-text-in-background)'};
  @media (min-width: 950px) {
    height: 100vh;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const LogoContainer = styled.div<HideMobileProps>`
  display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  padding-top: 4.5rem;
  & img {
    width: 100%;
  }
  @media (min-width: 639px) {
    & img {
      width: 80%;
    }
  }
  @media (min-width: 950px) {
    padding-top: 0;
    display: flex;
    background-image: url(${LoginBG});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (min-width: 1023px) {
    flex: 1;
  }
`

export const SubScreen = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: calc(100vh - 60px);
  justify-content: center;
  background: var(--color-background-darker);
`

export const SubContainer = styled.div`
  background: var(--color-background);
  width: 100%;
  min-height: 100%;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  @media (min-width: 600px) {
    margin-top: 0.35rem;
    width: 40rem;
  }
  @media (min-width: 1024px) {
    width: 70rem;
  }
`

export const FormContainer = styled.div`
  width: 100%;
  & footer {
    text-align: center;
  }
  @media (min-width: 950px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1023px) {
    flex: 1;
  }
`
export const FormCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & fieldset,
  & form {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 950px) {
    width: 90%;
    height: 90%;
    border-radius: 1.8rem;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.6);
    padding: 2.6rem;
    & form {
      width: 85%;
    }
  }
  @media (min-width: 1023px) {
    width: 75%;
  }
`

export const FormHeader = styled.div`
  margin-bottom: 4rem;
`

export const FormScrollable = styled.div`
  width: 100%;
  padding: 0.75rem 1rem;
  @media (min-width: 950px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    scrollbar-color: var(--color-background) var(--color-primary-lighter);
    scrollbar-width: thin;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: var(--color-primary-lighter);
      border-radius: 50px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--color-background);
      border-radius: 50px;
    }
  }
  @media (min-width: 1367px) {
    padding-right: 4rem;
  }
`
