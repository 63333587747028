import styled from 'styled-components'
import { TextProps } from './types'

export const FormTitle = styled.h4<TextProps>`
  //font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  margin: 1.6rem 3.2rem;
  text-align: center;
  display: ${({ showMobile }) => (!showMobile ? 'none' : 'block')};
  @media (min-width: 950px) {
    display: block;
  }
`
export const FormSubtitle = styled.p<TextProps>`
  padding: 0 1.6rem;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  display: ${({ showMobile }) => (!showMobile ? 'none' : 'block')};
  @media (min-width: 950px) {
    margin: 0 3.2rem inherit 3.2rem;
    display: block;
  }
`
