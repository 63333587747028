import styled from 'styled-components'
import { AiOutlineArrowRight } from 'react-icons/ai'

interface AvatarProps {
  imgUrl: string
}

const Container = styled.div`
  display: flex;
  padding: 0.45rem 0;
  border-bottom: 1px solid var(--color-border-lighter);
  cursor: pointer;
`

const AvatarWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin: 1rem 2rem;
`

const Avatar = styled.div<AvatarProps>`
  background-image: url(${({ imgUrl }) => imgUrl});
  background-size: contain;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  @media (min-width: 1024px) {
    width: 6rem;
    height: 6rem;
  }
`

const NameWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

const Name = styled.h5`
  font-size: 12pt;
  font-weight: 400;
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  padding: 0 1rem;
`

const IconArrow = styled(AiOutlineArrowRight)`
  font-size: 24pt;
  color: var(--color-primary);
  margin: 1.2rem;
  padding-right: 0.75rem;
`

const ListItem = {
  Container,
  AvatarWrapper,
  Avatar,
  NameWrapper,
  Name,
  IconContainer,
  IconArrow,
}

export default ListItem

// profilePic: {
//   width: 36,
//   height: 36,
//   borderRadius: 25,
// },
// blockTecnico: {
//   flexDirection: 'row',
//   paddingVertical: 6,
//   borderBottomWidth: 1,
//   borderBottomColor: '#d6d6d6',
// },
// iconContainer: {
//   alignItems: 'center',
//   justifyContent: 'center',
//   marginVertical: 8,
//   paddingHorizontal: 16,
// },
// tecnicoName: {
//   flex: 1,
//   justifyContent: 'center',
// },
// button: {
//   alignSelf: 'center',
// },
