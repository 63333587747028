import {
  EmptyObject,
  applyMiddleware,
  createStore,
  Middleware,
  Reducer,
} from 'redux'
import { PersistPartial } from 'redux-persist/lib/persistReducer'
import { UserAction, UserState } from './modules/user/types'
import { NoticiasAction, NoticiaState } from './modules/noticias/types.d'
import { TecnicosAction, TecnicosState } from './modules/tecnicos/types.d'
import {
  ReforcoSinalAction,
  ReforcoSinalState,
} from './modules/reforcoSinal/types.d'
// import { ClassesAction, ClassesState } from './modules/classes/types';

export interface StoreState {
  user: UserState
  noticias: NoticiaState
  tecnicos: TecnicosState
  reforcoSinal: ReforcoSinalState
}

export type StoreAction =
  | UserAction
  | NoticiasAction
  | TecnicosAction
  | ReforcoSinalAction

const create = (
  reducers: Reducer<EmptyObject & StoreState & PersistPartial, StoreAction>,
  middlewares: Middleware[],
) => {
  const enhancer = applyMiddleware(...middlewares)
  return createStore(reducers, enhancer)
}

export default create
