import createSagaMiddleware from 'redux-saga'
import { Middleware } from 'redux'
import { persistStore, persistReducer, purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createStore from './createStore'
import rootReducer from './modules/rootReducer'
import rootSaga from './modules/rootSaga'

const sagaMiddleware = createSagaMiddleware()

const middlewares: Middleware[] = [sagaMiddleware]

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, middlewares)

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
export const purgeAllState = (): void => purgeStoredState(persistConfig)

export default store
