import styled from 'styled-components'

interface MessageProps {
  secondary?: boolean
}

export const Message = styled.span<MessageProps>`
  text-align: center;
  font-size: 11pt;
  font-family: Roboto;
  font-weight: 700;
  color: ${({ secondary }) =>
    secondary ? 'var(--color-warning)' : 'var(--color-border-error)'};
`
