import React, { useState, useEffect } from 'react'
import { BarLoader, BounceLoader } from 'react-spinners'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { StoreState } from '../../../store/createStore'
import { getTecnicos, changeCep } from '../../../store/modules/tecnicos/actions'
import TechListItem from '../../../components/TechListItem'
import Styled from './styles'
import { SubContainer, SubScreen } from '../../../components/Containers'
import { FaExclamationTriangle } from 'react-icons/fa'

const SearchTechnicians: React.FC = () => {
  const { user, tecnicos } = useSelector((store: StoreState) => store)
  const { consumidor } = useSelector((store: StoreState) => store.user.userData.user)
  
  const tipo_consumidor = user.userData.user.tipo_consumidor;
  const [cepLoading, setCepLoading] = useState(true)
  const [endereco, setEndereco] = useState('')
  const [cep, setCep] = useState(consumidor.cep || '')
  const dispatch = useDispatch()
  

  useEffect(() => {
    setCepLoading(true)
    const stripCep = cep.replace(/[\\/.-]/g, '') 
   
    if (stripCep.length === 8) {
      axios
        .get(`https://brasilapi.com.br/api/cep/v1/${stripCep}`)
        .then(res => {
          const stringEndereço = String(
            `${res.data.street} ${res.data.neighborhood}, ${res.data.city} - ${res.data.state}`,
          )
          dispatch(changeCep({ cep: stripCep }))
          dispatch(getTecnicos({ cep: stripCep }))
          setEndereco(stringEndereço)
          setCepLoading(false)
        })
        .catch(e => {
          setCepLoading(false)
          setEndereco(
            'Não foi Possível Encontrar um Endereço a partir deste CEP',
          )
        })
    }
  }, [cep, dispatch])

  if(!tipo_consumidor){
    return <Styled.ContainerNotConsumidor>
              <Styled.TittleAcess><FaExclamationTriangle />Acesso apenas para usuários Consumidores</Styled.TittleAcess>
          </Styled.ContainerNotConsumidor>
  }
  return (
    <SubScreen>
      <SubContainer>
        <Styled.SearchForm>
          <Styled.IconClose onClick={() => setCep('')} />
          <Styled.Input
            placeholder="Digite seu CEP"
             value={cep}
            onChange={e => setCep(e.target.value)}
          />
        </Styled.SearchForm>
        <Styled.CepAddressContainer>
          <div>
            <Styled.CepAddressMessage>
              Resultados mais próximos de:{' '}
            </Styled.CepAddressMessage>
          </div>
          {!cepLoading ? (
            <Styled.CepAddress>{endereco}</Styled.CepAddress>
          ) : (
            <Styled.Loader>
              <BarLoader color="#FD7E77" width={160} />
            </Styled.Loader>
          )}
        </Styled.CepAddressContainer>
        <Styled.TechList>
          {tecnicos.loading ? (
            <Styled.LoadingWrapper>
              <BounceLoader color="#FD7E77" size={60} />
            </Styled.LoadingWrapper>
          ) : tecnicos.data.tecnicos.length > 0 ? (
            tecnicos.data.tecnicos.map(tecnico => (
              <TechListItem
                key={`${tecnico.nome} ${tecnico.sobrenome}`}
                data={tecnico}
              />
            ))
          ) : (
            <Styled.NotFoundMessage>
              Não foi encontrado nenhum técnico com atuação no CEP informado.
            </Styled.NotFoundMessage>
          )}
        </Styled.TechList>
      </SubContainer>
    </SubScreen>
  )
}

export default SearchTechnicians
