import axios from 'axios'

const baseURL = 'https://django-clarotv.herokuapp.com/api/'

const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
api.interceptors.request.use(config => {
  const token = localStorage.getItem(
    '$2y$12$2FQiAF3LwQkymP5on08B4eyXs7EOVcKvELd876Va0Vj3cUuoBMG56',
  )
  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token || '',
    },
  }
  return newConfig
})

export default api
