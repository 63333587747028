import { NoticiaState, NoticiasAction } from './types.d'

const initialState: NoticiaState = {
  data: [
    {
      id: null,
      titulo: '',
      descricao: '',
      data_hora: '',
      anexo: '',
      thumbnail: null,
      tipo_anexo: '',
      usuario_criador: '',
      qtd_gostei: 0,
      qtd_entendi: 0,
      qtd_nao_entendi: 0,
      gostei: false,
      entendi: false,
      nao_entendi: false,
    },
  ],
  loading: false,
  status: '',
  message: null,
}

const noticias = (
  state = initialState,
  action: NoticiasAction,
): NoticiaState => {
  switch (action.type) {
    case '@noticias/GET':
      return {
        ...initialState,
        loading: true,
      }
    case '@noticias/GET_SUCCESS':
      return {
        ...state,
        loading: false,
        status: 'success',
        data: action.payload,
      }
    case '@noticias/GET_ERROR':
      return {
        ...state,
        loading: false,
        status: 'error',
        message: action.payload,
      }
    default:
      return initialState
  }
}

export default noticias
