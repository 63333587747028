import styled from 'styled-components'
import { MdAddToQueue } from 'react-icons/md'
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck'
import { IoClose } from 'react-icons/io5'
import { Link } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80vh;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  padding: 0 2.5rem;
  background: var(--color-primary-lighter);
  color: var(--color-text-in-primary-background);
  @media (min-width: 950px) {
    padding: 0;
  }
`

const HeaderIcon = styled(NetworkCheckIcon)`
  font-size: 22pt;
  margin-right: 0.75rem;
`

const SearchForm = styled.form`
  display: flex;
  border-bottom: 1px solid var(--color-border-lighter);
`

const IconClose = styled(IoClose)`
  cursor: pointer;
  font-size: 24pt;
  color: var(--color-icons-secondary);
  margin: 1.2rem;
  padding-right: 0.75rem;
  border-right: 1px solid var(--color-border-lighter);
`

const Input = styled.input`
  width: 100%;
  background: transparent;
  border: 0;
`

const HeaderTitle = styled.h2`
  font-family: Poppins;
  font-weight: 400;
  font-size: 14pt;
  text-align: center;
  @media (min-width: 950px) {
    font-size: 16pt;
  }
`

const MessageDefault = styled.h3`
  width: 100%;
  border-top: 1px solid var(--color-border-lighter);
  border-bottom: 1px solid var(--color-border-lighter);
  margin: 4rem 0;
  padding: 4rem 0;
  font-family: Poppins;
  text-align: center;
  color: var(--color-text-in-background);
  font-size: 16pt;
`

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-primary);
  color: var(--color-text-in-primary-background);
  border-radius: 0.75rem;
  margin-bottom: 7.5rem;
  padding: 2rem 3.5rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
`

const IconAddSignal = styled(MdAddToQueue)`
  font-size: 22pt;
  font-weight: 600;
  margin-right: 1rem;
`

const AddSignal = styled.h4`
  font-size: 14pt;
  font-weight: 300;
`

const LoadingWrapper = styled.div`
  margin: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Signal = {
  Container,
  List,
  Header,
  HeaderIcon,
  HeaderTitle,
  SearchForm,
  IconClose,
  Input,
  MessageDefault,
  Button,
  IconAddSignal,
  AddSignal,
  LoadingWrapper,
}

export default Signal
