import { format } from 'date-fns'

// 2021-06-15T17:01:22.606406
const beautifyDateTime = (rawDate: string) => {
  const [date, time] = rawDate.split('T')
  const [year, month, day] = date.split('-')
  const [hour, min, sec] = time.split(':')
  const fomattedDate = format(
    new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hour),
      Number(min),
      Math.round(Number(sec)),
    ),
    'dd/MM/yyyy HH:mm:ss',
  )
  return fomattedDate
}

export default beautifyDateTime
