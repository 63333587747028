import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import isAuthenticated from '../utils/auth'
import { useSelector } from 'react-redux'
import { StoreState } from '../store/createStore'



interface PrivateRouteProps {
  path: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, path }) => {

  const { user } = useSelector((store: StoreState) => store)
  const tipo_consumidor = user.userData.user.tipo_consumidor;
  
  return (
    <Route
      path={path}
      render={({ location }) =>
        !tipo_consumidor ? (<Redirect to={{pathname: '/permissao',
        state: { from: location },}} /> ) :
        isAuthenticated() === true ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
