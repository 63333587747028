import styled from "styled-components";


export const ProfileContainer = styled.div`
    background: #ffffff;
    padding-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ProfileCard = styled.div`
    border: 1px solid #c4c4c4;
    background: #f1f1f1;
    height: 70%;
    width:90rem;
    display: flex;
    flex-direction:column;
    align-items:center;
    padding-top:60px;
    @media (max-width:415px) {
        width:40rem;
    }
`
export const CardInfo = styled.article`
    background: #ffffff;
    height: 80px;
    width: 90%;
    border-radius:5px;
    border-bottom:1px solid black;
    margin-bottom:30px;
    display: flex;
    align-items:center;
    justify-content:center;
    gap:30px;
    padding:15px;
    @media (max-width:415px) {
        gap:20px;
        height: 60px;
    }
`
export const BoxInfo = styled.div`
    width: 90%;
    padding: 20px;
    background: #ffffff;
    border-radius:5px;
    margin-bottom:15px;
`
export const TextInfo = styled.p`
    font-weight:bold;
    font-size:20px;
    text-align:center;
    @media (max-width:415px) {
        font-size:12px;
    }
`
export const ButtonRequest = styled.button`
    width: 200px;
    height: 50px;
    color: #ffffff;
    background: #d00000;
    border:none;
    border-radius: 8px;
    @media (max-width:415px) {
        width:120px;
        height: 30px;
        font-size:12px;
    }
`
