import React from 'react'
import HomeIcon from '@material-ui/icons/HomeRounded'
import ContactsIcon from '@material-ui/icons/Contacts'
import TvIcon from '@material-ui/icons/Tv'
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck'
import CreditsIcon from '@material-ui/icons/AttachMoneyRounded'
import PaymentMethodIcon from '@material-ui/icons/CreditCard'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SettingsIcon from '@material-ui/icons/Settings'

import Home from '../../../pages/App/Home'
import SearchTechnicians from '../../../pages/App/SearchTechnicians'
import MyDevices from '../../../pages/App/MyDevices'
import SignalReinforcement from '../../../pages/App/Home/SignalReinforcement'
import  Profile  from '../../../pages/App/Profile'

const menuRoutes = [
  {
    name: 'Home',
    IconComponent: HomeIcon,
    path: '/app/home',
    RouteComponent: Home,
  },
  {
    name: 'Buscar Técnicos',
    IconComponent: ContactsIcon,
    path: '/app/tecnicos/buscar',
    RouteComponent: SearchTechnicians,
  },
  {
    name: 'Meus Equipamentos',
    IconComponent: TvIcon,
    path: '/app/dispositivos',
    RouteComponent: MyDevices,
  },
  {
    name: 'Reforço de Sinal',
    IconComponent: NetworkCheckIcon,
    path: '/app/reforco_de_sinal',
    RouteComponent: SignalReinforcement,
  },
  {
    name: 'Recarga Online',
    IconComponent: CreditsIcon,
    externalLink: 'https://portal.claro-tv.m4u.com.br/?source=app',
    RouteComponent: React.Fragment,
  },
  {
    name: 'Meios de Pagamento',
    IconComponent: PaymentMethodIcon,
    path: '/pagamentos',
    disabled: true,
    RouteComponent: React.Fragment,
  },
  {
    name: 'Perfil',
    IconComponent: AccountCircleIcon,
    path: '/perfil',
    disabled: false,
    RouteComponent: Profile,
  },
  {
    name: 'Configurações',
    IconComponent: SettingsIcon,
    path: '/config',
    disabled: true,
    RouteComponent: React.Fragment,
  },
]

export default menuRoutes
