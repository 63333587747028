import styled from "styled-components";


export const RequestContainer = styled.main`
    display: flex;  
    width:100%;
    height:100vh;
    overflow:hidden;
    @media (max-width: 834px) {
       display:block;
    }
`
export const BannerContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content:center;
    align-items:center;
    background: #ffffff;
    @media (max-width: 834px) {
       display: none;
    }
`
export const Banner = styled.img`
    width: 60%;
    height: 40%;
    margin: 0 auto;
`
export const RequestFormContainer = styled.div`
    background: #ffffff;
    width: 50%;
    height: 100vh;
    display:flex;
    align-items:center;
    justify-content:center;
    @media (max-width: 834px) {
        width: 100%;
    }
`
export const RequestForm = styled.div`
    border-radius:15px;
    width: 60%;
    height: 70%;
    box-shadow: 2px 2px 2px 2px;
    padding:20px;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    @media (max-width: 834px) {
        width: 80%;
        height: 80%;
    }
`
export const RequestTittle = styled.h1`
    color: #909090;
    font-size: 20px;
    text-align:center;
    margin-bottom:25px;
`
export const DataContainer = styled.div`
    width: 90%;
    height: 200px;
    display: flex;
    flex-direction:column;
`
export const InputEmail = styled.input`
    height: 50px;
    margin-top:10px;
    border-radius:5px;
    border: 1px solid #c4c4c4c4;
    box-shadow:1px 1px 1px 1px #c4c4c4c4;
    padding: 10px;
    @media (max-width: 834px) {
       &:-ms-input-placeholder {
           font-size:5px;
       }
       &:-webkit-input-placeholder {
           font-size:5px;
       }
       &:-moz-placeholder {
           font-size:5px;
       }     
    }  
`
export const RequestButtom = styled.button`
    height: 50px;
    width: 200px;
    background:#d00000;
    color:#ffffff;
    border-radius: 5px;
    border:none;
    cursor:pointer;
    &:disabled {
        background: #909090;
        cursor:initial;
    }
`
export const ErrorEmail = styled.p`
    font-size:12px;
    color:#d00000;
    text-align:center;
    margin-top:20px;
`
export const SucessEmail = styled.p`
    font-size:12px;
    color:#00b000;
    text-align:center;
    margin-top:20px;
`