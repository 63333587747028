import React, { useState } from 'react'
import { ReforcoSinalProps } from '../../../../../../store/modules/reforcoSinal/types'
import Item from './styles'
import beautifyDateTime from '../../../../../../utils/date'

type RequestItemProps = {
  item: ReforcoSinalProps
}

const RequestItem: React.FC<RequestItemProps> = ({ item }) => {
  const [status] = useState({
    0: {
      name: 'Recebido',
      color: '--color-primary-lighter',
    },
    1: {
      name: 'Em Fila',
      color: '--color-primary-highlight',
    },
    2: {
      name: 'Concluído',
      color: '--color-success-darker',
    },
    3: {
      name: 'Falha',
      color: '--color-border-error',
    },
  })

  return (
    <Item.CardWrapper>
      <Item.Body>
        <Item.IconWrapper>
          <Item.Icon color={status[item.status].color} />
          <Item.Status color={status[item.status].color}>
            {status[item.status].name}
          </Item.Status>
        </Item.IconWrapper>
        <Item.Details>
          <Item.Info>CAID: {item.CAID}</Item.Info>
          <Item.Info>SCID: {item.SCID}</Item.Info>
        </Item.Details>
        <Item.DateWrapper>
          <Item.Date>Solicitado em:</Item.Date>
          <Item.Date>
            {beautifyDateTime(item.data_recebimento).split(' ')[0]}
          </Item.Date>
          <Item.Date>
            {beautifyDateTime(item.data_recebimento).split(' ')[1]}
          </Item.Date>
        </Item.DateWrapper>
      </Item.Body>
      {item.mensagem_retorno && (
        <Item.Footer>
          <Item.FooterMessage color={status[item.status].color}>
            Mensagem: {item.mensagem_retorno}
          </Item.FooterMessage>
          {item.data_retorno && (
            <Item.Date>{beautifyDateTime(item.data_retorno)}</Item.Date>
          )}
        </Item.Footer>
      )}
    </Item.CardWrapper>
  )
}

export default RequestItem
