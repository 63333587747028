export const maskPhone = (rawNumber: string): string => {
  if (rawNumber.length <= 14) {
    const formatPhone = rawNumber
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
    return formatPhone
  }
  const formatPhone = rawNumber
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
  return formatPhone
}

export const unmaskPhone = (maskedValue: string): string => {
  const unmaskedInput = maskedValue.replace(/[/().-]/g, '')
  return unmaskedInput
}

export const validatePhone = (phoneNumber: string): boolean => {
  const matchPhone = /\(\d{2}\) \d{4,5}-\d{4}/
  return matchPhone.test(phoneNumber)
}
