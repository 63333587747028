import React, { useEffect, useState, FormEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { StoreState } from '../../store/createStore'
import { signIn, signInResetStatus } from '../../store/modules/user/actions'
import banner from '../../assets/img/banner.png'
import { maskCpfCnpj, validateCpfCnpj } from '../../utils'
import { Input, ToggleSpan, PasswordGroup } from '../../components/Form/Inputs'
import { Button, SecondaryLink } from '../../components/Buttons'
import { FormTitle } from '../../components/Text'
import {
  LogoContainer,
  FormContainer,
  FormCard,
} from '../../components/Containers'
import Styled from './styles'

const Login: React.FC = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state: StoreState) => state)

  const [cpf, setCpf] = useState('')
  const [errorCPF, setErrorCPF] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const handleCpf = (inputValue: string) => {
    const maskedCpfCnpj = maskCpfCnpj(inputValue)
    setCpf(maskedCpfCnpj)
    if (validateCpfCnpj(maskedCpfCnpj)) {
      setErrorCPF(false)
      const unmaskedInput = maskedCpfCnpj.replace(/[/.-]/g, '')
      setUsername(unmaskedInput)
    } else {
      setErrorCPF(true)
      setUsername('')
    }
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    if (username !== '' && password !== '' && !errorCPF) {
      dispatch(
        signIn({
          username,
          password,
        }),
      )
    }
  }

  useEffect(() => {
    if (user.status === 'error') toast.error(user.message)
    if (user.status === 'success') {
      dispatch(signInResetStatus())
      history.push('/app')
    }
  }, [user, dispatch, history])
  return (
    <>
      <Styled.Container>
        <LogoContainer>
          <img src={banner} alt="Claro TV Pré-Pago" />
        </LogoContainer>
        <FormContainer>
          <FormCard>
            <FormTitle>Bem Vindo ao Claro TV.</FormTitle>
            <Styled.Form onSubmit={submitForm}>
              <fieldset>
                <Input
                  type="text"
                  name="CPF"
                  placeholder="CPF"
                  value={cpf}
                  onChange={e => handleCpf(e.target.value)}
                  hasError={errorCPF}
                  pattern="(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)"
                />
                <PasswordGroup>
                  <Input
                    type={!passwordVisibility ? 'password' : 'text'}
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="Senha"
                  />
                  <ToggleSpan
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ToggleSpan>
                </PasswordGroup>
              </fieldset>
              <SecondaryLink to="/solicitar">Esqueci minha senha</SecondaryLink>
              {!user.loading ? (
                <Button type="submit">Login</Button>
              ) : (
                <Button type="button">
                  <BeatLoader color="#FFF" size={10} />
                </Button>
              )}
            </Styled.Form>
            <footer>
              <SecondaryLink to="/cadastro">
                Novo no Claro TV? <b>Cadastre-se</b>
              </SecondaryLink>
            </footer>
          </FormCard>
        </FormContainer>
      </Styled.Container>
      <ToastContainer toastStyle={{fontSize: '15px'}} />
    </>
  )
}

export default Login
