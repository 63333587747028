import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ContentLoader from 'react-content-loader'
import { getNoticias } from '../../../store/modules/noticias/actions'
import { NoticiaResponseProps } from '../../../store/modules/noticias/types'
import Styled from './styles'

const Home: React.FC = () => {

  const dispatch = useDispatch()
  const { noticias } = useSelector((store): any => store)

  useEffect(() => {
    dispatch(getNoticias())
  }, [dispatch])

  return (
    <Styled.Container>
      {!noticias.loading ? (
        <Carousel
          infiniteLoop
          interval={3000}
          showThumbs={false}
          showStatus={false}
        >
          {noticias.data.length > 0 ? (
            noticias.data.map((noticia: NoticiaResponseProps) => (
              <div key={noticia.id}>
                <Styled.Banner imgUrl={noticia.thumbnail} />
              </div>
            ))
          ) : (
            <></>
          )}
        </Carousel>
      ) : (
        <ContentLoader
          speed={1}
          width="100%"
          height={400}
          style={{ width: '100%' }}
          backgroundColor="#ddd"
          foregroundColor="#f0f0f0"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="400" />
        </ContentLoader>
      )}
    </Styled.Container>
  )
}

export default Home
