import { action } from 'typesafe-actions'
import { TecnicosResponseProps } from './types.d'

interface cepProp {
  cep: string
}

interface cpfTecnicoProp {
  chave: string
}

export const changeCep = (cep: cepProp) => action('@tecnicos/CHANGE_CEP', cep)

export const getTecnicos = (cep: cepProp) => action('@tecnicos/GET', cep)

export const getTecnicosSuccess = (data: TecnicosResponseProps) =>
  action('@tecnicos/GET_SUCCESS', data)

export const getTecnicosError = (message: string) =>
  action('@tecnicos/GET_ERROR', { message })

export const getDetalhesTecnico = (chave: cpfTecnicoProp) =>
  action('@tecnicos/detalhes/GET', chave)

export const getDetalhesTecnicoSuccess = (data: TecnicosResponseProps) =>
  action('@tecnicos/detalhes/GET_SUCCESS', data)

export const getDetalhesTecnicoError = (message: string) =>
  action('@tecnicos/detalhes/GET_ERROR', { message })
