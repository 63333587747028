import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { StoreState } from '../../../../store/createStore'
import { SubScreen, SubContainer } from '../../../../components/Containers'

import Details from './styles'

const DetailsTechnicians: React.FC = () => {
    const { nome, sobrenome, endereco, cidade, cep, telefone, foto_tecnico } =
    useSelector((store: StoreState) => store.tecnicos.tecnicoSelecionado)
    const history = useHistory()
    const prettierPhone = () => {
    const ddd = telefone.slice(0, 2)
    const number = telefone.slice(2)
    const first = number.length === 8 ? number.slice(0, 4) : number.slice(0, 5)
    const second = number.length === 8 ? number.slice(4) : number.slice(5)
    return `(${ddd})${first}-${second}`
  }
  
  return (
    <SubScreen>
      <SubContainer>
        <Details.Picture imgUrl={foto_tecnico} />
        <Details.Profile>
          <Details.Name>{`${nome} ${sobrenome}`}</Details.Name>
        </Details.Profile>
        <Details.Info>
          <Details.MapIcon />
          <Details.Address>
            {`${endereco || 'Endereço não informado'}, ${cidade} - CEP: ${cep}`}
          </Details.Address>
        </Details.Info>
        <Details.Contact>
          <Details.Phone>{prettierPhone()}</Details.Phone>
        </Details.Contact>
        <Details.Contact
          outline
          onClick={() => history.push('/app/tecnicos/buscar')}
        >
          <Details.Phone>
            <Details.BackIcon />
            Voltar
          </Details.Phone>
        </Details.Contact>
      </SubContainer>
    </SubScreen>
  )
}

export default DetailsTechnicians
