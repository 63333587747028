import React, { useState } from 'react';
import banner from '../../../assets/img/banner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import api from '../../../services/api';

import {
       RequestContainer,
       BannerContainer,
       Banner,
       RequestFormContainer,
       RequestForm,
       RequestTittle,
       InputEmail,
       DataContainer,
       RequestButtom,
       ErrorEmail,
       SucessEmail
       } from './styles';

const RequestNewpassord = () => {

  const warningIcon = <FontAwesomeIcon icon={faExclamationCircle} />
  const sucessIcon = <FontAwesomeIcon icon={faCheckCircle} />
  const [ email, setEmail] = useState<string>('');
  const [ erroEmail, setErroEmail] = useState<boolean>(false);
  const [ sucess, setSucess ] = useState<boolean>(false)
  const [ erroRequest, setErroRequest ] = useState<boolean>(false);


  const requestNewPassword = () => {

    if (email.indexOf('@') > -1) {
      
    api.post('/usuarios/solicitar_nova_senha/', {"email":email})
    .then((resp) => {
      console.log(resp.data);
      setSucess(true);
      setTimeout( () => setSucess(false), 5000)
    })
    .catch((err) => {
      console.log(err)
      setErroRequest(true)
      setTimeout( () => setErroRequest(false), 5000)
    })
      } else {
        setErroEmail(true);
        setTimeout( () => setErroEmail(false), 5000)
      }
  }

  return (
        <RequestContainer>
          <BannerContainer>
              <Banner src={banner} />
          </BannerContainer>
              <RequestFormContainer>
                <RequestForm>
                  <RequestTittle>Solicitar nova senha</RequestTittle>
                    <DataContainer>                     
                      <InputEmail
                       type='text'
                        placeholder='Informe o email utilizado no cadástro'
                        onChange={ (e) => setEmail(e.target.value)}
                        />
                    {erroEmail && <ErrorEmail> {warningIcon}  Informe um email válido!</ErrorEmail>}
                    {erroRequest && <ErrorEmail> {warningIcon}  O e-mail informado não consta em nosso sistema!</ErrorEmail>}
                    {sucess && <SucessEmail>{sucessIcon} Nova senha solicitada com sucesso.<br/> verifique sua caixa de entrada</SucessEmail>}
                    </DataContainer>
                    <RequestButtom
                      disabled={email.length <= 10 ? true : false}
                      onClick={ () => requestNewPassword()}
                      >Solicitar
                      </RequestButtom>
                </RequestForm>
              </RequestFormContainer>         
        </RequestContainer>
  );
}

export default RequestNewpassord