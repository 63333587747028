import { ReforcoSinalAction, ReforcoSinalState } from './types.d'

const initialState: ReforcoSinalState = {
  data: [
    // {
    //   id: 0,
    //   CPF: '24191781308',
    //   CAID: '178506927778',
    //   SCID: '138349695564',
    //   status: 0,
    //   mensagem_retorno: null,
    //   data_recebimento: '2021-06-15T17:01:22.606406',
    //   data_retorno: null,
    // },
  ],
  status: null,
  loading: false,
  message: null,
  statusRequest: null,
  loadingRequest: false,
  messageRequest: null,
}

const tecnicos = (
  state = initialState,
  action: ReforcoSinalAction,
): ReforcoSinalState => {
  switch (action.type) {
    case '@reforco_sinal/GET':
      return {
        ...state,
        loading: true,
      }
    case '@reforco_sinal/GET_SUCCESS':
      return {
        ...state,
        data: action.payload,
        status: 'success',
        loading: false,
      }
    case '@reforco_sinal/GET_ERROR':
      return {
        ...state,
        data: [],
        message: action.message,
        status: 'error',
        loading: false,
      }
    case '@reforco_sinal/POST':
      return {
        ...state,
        statusRequest: null,
        loadingRequest: true,
        messageRequest: null,
      }
    case '@reforco_sinal/POST_SUCCESS':
      return {
        ...state,
        statusRequest: 'success',
        loadingRequest: false,
      }
    case '@reforco_sinal/POST_ERROR':
      return {
        ...state,
        messageRequest: action.message,
        statusRequest: 'error',
        loadingRequest: false,
      }
    case '@reforco_sinal/CLEAR':
      return {
        ...state,
        status: null,
        loading: false,
        message: null,
        statusRequest: null,
        loadingRequest: false,
        messageRequest: null,
      }
    default:
      return state
  }
}

export default tecnicos
