import styled from "styled-components";


export const ChangeContainer = styled.div`
    background: #f1f1f1;
    height: 100vh;
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
export const BoxContent = styled.div`
    position: relative;
    width: 30%;
    height: 70%;
    background: #ffffff;
    border-radius:8px;
    padding: 30px;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:50px;
    @media (max-width:415px){
        gap:30px;
    }
   
    @media (max-width:1700px){
        width: 90%;
        height: 60%;
    }
`
export const TittleBox = styled.h4`
    text-align:center;
    font-size:20px;
    color: #909090;
`
export const InputPassword = styled.input`
    width: 100%;
    height: 60px;
    border: none;
    background: #f1f1f1;
    border-radius:5px;
    padding:15px;
   
`
export const MessageError = styled.p`
    font-size:18px;
    color: #d00000;
    text-align:center;
`
export const MessageSucess = styled.p`
    font-size:18px;
    color: #00bb00;
    text-align:center;
`
export const Envolve = styled.div`
    position:relative;
    width: 100%;
    display:flex;
`
export const ReturnButtom = styled.button`
    background: transparent;
    border: none;
    position: absolute;
    top:10px;
    left:20px;
`
