import { purgeAllState } from '../../store'

const isAuthenticated = (): boolean => {
  const Token = localStorage.getItem(
    '$2y$12$2FQiAF3LwQkymP5on08B4eyXs7EOVcKvELd876Va0Vj3cUuoBMG56',
  )
  if (Token && Token.includes('Token')) return Token.split(' ')[1].length > 10
  return false
}

export const logout = (): void => {
  localStorage.removeItem(
    '$2y$12$2FQiAF3LwQkymP5on08B4eyXs7EOVcKvELd876Va0Vj3cUuoBMG56',
  )
  purgeAllState()
}

export default isAuthenticated
