import React from 'react'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import SupportIcon from '@material-ui/icons/HeadsetMicRounded'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import LaunchIcon from '@material-ui/icons/Launch'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { StoreState } from '../../../store/createStore'
import { logout } from '../../../utils/auth'

import menuRoutes from '../menuRoutes'
import avatarPlaceholder from '../../../assets/img/avatar.png'
import Content from './styles'
import useStyles from '../styles'

const DrawerContent: React.FC = () => {

  const { user } = useSelector((store: StoreState) => store.user.userData)
  const { pathname } = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const LinkFoto = user.consumidor.foto
 

  return (
    <div>
      <div className={classes.toolbar}>
        <Content.AvatarWrapper>
          { LinkFoto 
            ? <Content.AvatarCons src={LinkFoto} /> 
            : <Content.Avatar imgUrl={ avatarPlaceholder} />}         
          <Content.Name>{`${user.name}`}</Content.Name>
        </Content.AvatarWrapper>
      </div>
      <Divider />
      <List className={classes.text}>
        {menuRoutes.map(route =>
          route.externalLink ? (
            <a
              key={route.name}
              href={route.externalLink}
              target="_blank"
              rel="noreferrer nofollow noopener external"
            >
              <ListItem
                key={route.name}
                classes={{ selected: classes.selected }}
                className={classes.listItem}
                button
                disabled={route.disabled === true}
              >
                <ListItemIcon>
                  <route.IconComponent style={{ fontSize: 22 }} />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.text,
                  }}
                  primary={route.name}
                />
                <ListItemIcon>
                  <LaunchIcon style={{ fontSize: 16 }} />
                </ListItemIcon>
              </ListItem>
            </a>
          ) : (
            <Link key={route.name} to={route.path || '#'}>
              <ListItem
                key={route.name}
                classes={{ selected: classes.selected }}
                className={classes.listItem}
                button
                selected={pathname === route.path}
                disabled={route.disabled === true}
              >
                <ListItemIcon>
                  <route.IconComponent style={{ fontSize: 22 }} />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.text,
                  }}
                  primary={route.name}
                />
              </ListItem>
            </Link>
          ),
        )}
        <ListItem className={classes.listItem}>
          <ListItemIcon>
            <SupportIcon style={{ fontSize: 22 }} />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.text,
            }}
            primary="SAC - 0800 208 5600"
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            logout()
            setTimeout(() => history.push('/login'), 500)
            setTimeout(() => document.location.reload(), 500)
          }}
        >
          <ListItemIcon>
            <LogoutIcon className={classes.logoutIcon} />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.text,
            }}
            primary="Sair"
          />
        </ListItem>
      </List>
    </div>
  )
}

export default DrawerContent
