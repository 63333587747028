import Swal from 'sweetalert2'

const triggerMaintenanceAlert = (targetMain: boolean): void => {
  Swal.fire({
    target: targetMain ? 'main' : 'body',
    customClass: targetMain
      ? {
          container: 'position-absolute',
        }
      : {},
    title: 'Recurso Indisponível!',
    text: 'Funcionalidade em Manutenção. Será disponibilizada muito em breve!',
    icon: 'warning',
    timer: 5000,
    confirmButtonText: 'Entendido',
    confirmButtonColor: '#E14B5A',
  })
}

export default triggerMaintenanceAlert
