import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AiOutlineArrowRight } from 'react-icons/ai'

interface OptionProps {
  selected: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
`

const SelectWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Title = styled.h2`
  margin: -12rem 0 8rem 0;
  padding: 0 2.5rem;
  font-size: 18pt;
  text-align: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const ButtonOption = styled.div<OptionProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 18rem;
  border: 1px solid var(--color-border-lighter);
  background: ${({ selected }) =>
    selected ? 'var(--color-primary)' : 'var(--color-background)'};
  color: ${({ selected }) =>
    selected ? 'var(--color-text-in-primary-background)' : 'inherit'};
  border-radius: 0.75rem;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.45);
`

const Description = styled.p`
  align-self: center;
  width: 80%;
  font-size: 13pt;
  font-weight: 300;
  text-align: center;
  color: var(--color-text-in-background);
  margin: 2.75rem 0;
`

const OptionTitle = styled.h5`
  margin: 0.75rem 0;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
`

const LinkSubmit = styled(Link)`
  align-self: center;
  display: flex;
  align-items: center;
  font-size: 16pt;
  background-color: var(--color-primary);
  padding: 2rem 5.5rem;
  border-radius: 1rem;
  color: var(--color-text-in-primary-background);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
`

const ArrowIcon = styled(AiOutlineArrowRight)`
  font-size: 14pt;
  margin-left: 1rem;
`

const ButtonSubmit = styled.div`
  cursor: not-allowed;
  align-self: center;
  font-size: 14pt;
  background-color: var(--color-text-disabled);
  padding: 2rem 5.5rem;
  border-radius: 1rem;
  color: var(--color-text-in-primary-background);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
`

const Device = {
  Container,
  SelectWrapper,
  Title,
  ButtonsContainer,
  ButtonOption,
  OptionTitle,
  ButtonSubmit,
  LinkSubmit,
  ArrowIcon,
  Description,
}

export default Device
