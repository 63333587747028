import { takeLatest, call, put, all } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import api from '../../../services/claroGerencialApi'

export function* getReforcoSinal(
  action: ActionType<typeof actions.getReforcoSinal>,
) {
  try {
    const { cpf } = action.payload
    const { data } = yield call(api.get, '/listar_reforcos_sinal/', {
      params: { cpf },
    })
    yield put(actions.getReforcoSinalSuccess(data))
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message ||
      'Falha inesperada ao Obter Lista de Reforços de sinal'
    yield put(actions.getReforcoSinalError(errorMessage))
  }
}

export function* createReforcoSinal(
  action: ActionType<typeof actions.createReforcoSinal>,
): Generator<any, any, any> {
  try {
    const { payload } = action
    const response = yield call(api.post, 'soliticitar_reforco_sinal/', payload)
    yield put(actions.createReforcoSinalSuccess(response.data))
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || 'Falha inesperada ao Obter Técnicos'
    yield put(actions.createReforcoSinalError(errorMessage))
  }
}

export default all([
  takeLatest('@reforco_sinal/GET', getReforcoSinal),
  takeLatest('@reforco_sinal/POST', createReforcoSinal),
])
